#skip a {
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

#skip a:focus {
  left: auto;
  right: auto;
  z-index: 100000;
}

.skip-link {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  font-size: 14px;
  font-weight: 600;
  height: auto !important;
  padding: 15px 23px 14px;
  width: auto !important;
}

.link {
  @apply underline;

  &:hover {
    @apply no-underline;
  }
}

.bold-link {
  @apply font-bold underline;

  &:hover {
    @apply no-underline;
  }
}

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-xl font-bold;
}

h3 {
  @apply text-lg;
}

.button {
  @apply px-8 py-3 leading-none rounded font-bold inline-block text-button text-center border-2 border-transparent;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: auto;

    @apply bg-grey-light-disabled text-grey-dark-disabled;
  }

  &.compact {
    @apply px-4;
  }
}

.button-full {
  width: 100%;

  @screen lg {
    width: auto;
  }
}

.button-primary {
  @extend .button;

  @apply bg-primary-yellow text-black;

  &:hover {
    @apply border-black;
  }
}

.button-blue {
  @extend .button;

  @apply bg-cyan;

  &:hover {
    @apply border-black;
  }
}

.button-white {
  @extend .button;

  &:hover {
    @apply border-black;
  }
}

.button-danger {
  @extend .button;

  @apply border border-red bg-transparent text-red;

  &:after {
    @apply border border-transparent;
  }

  &:hover {
    @apply border-2;
  }

  &:hover:after {
    @apply border-0;
  }
}

.button-camera {
  @extend .button;

  @apply bg-red-02 text-white;

  &:hover {
    @apply border-black;
  }
}

.button-grey {
  @extend .button;

  @apply bg-medium-grey-02 text-white;

  &:hover {
    @apply border-black;
  }
}

.button-secondary {
  @extend .button;

  @apply bg-black text-white;

  &:hover {
    @apply border-primary-yellow;
  }
}

.button-form {
  @extend .button;

  @apply bg-primary-grey-blue-02 text-white;

  &:hover {
    @apply border-primary-yellow;
  }
}

.button-link {
  @extend .button;

  @apply font-normal underline;

  &:hover {
    @apply no-underline;
  }
}

.button-form-dark-grey {
  @extend .button;

  @apply bg-dark-grey-02 text-white;

  &:hover {
    @apply border-primary-yellow;
  }
}

.button-form-secondary {
  @extend .button;

  @apply border border-primary-grey-blue-02 bg-transparent text-primary-grey-blue-02;

  &:after {
    @apply border border-transparent;
  }

  &:hover {
    @apply border-2 border-black;
  }

  &:hover:after {
    @apply border-0;
  }
}

.button-progress-active {
  @extend .button;

  @apply border-primary-yellow bg-white text-primary-grey-blue-02;
}

@screen lg {
  .button-primary {
    @apply mt-0;
  }

  .button-secondary {
    @apply mt-0;
  }
}

input.input,
select.input,
textarea.input {
  @apply rounded px-4 py-2 text-button;

  &.bordered {
    @apply border-primary-grey-blue-faded border-2;
  }
}

input[type="file"]::file-selector-button {
  @apply invisible;
}

.back-arrow {
  transform: scale(0.8, 1.4);
  position: relative;
  top: -0.1rem;
  display: inline-block;
}

.down-arrow {
  transform: scale(1.4, 0.8);
  position: relative;
  display: inline-block;
}

select.input {
  border-width: 0;

  &:hover {
    cursor: pointer;
  }
}

.admin-grid {
  @apply bg-white text-sm;

  .action > a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  > thead > tr > th {
    @apply text-sm text-left py-6 pr-6 font-normal;

    &:first-child {
      @apply pl-6;
    }

    &:last-child {
      @apply pr-0;
    }
  }

  > tbody > tr > td {
    @apply text-left py-4-5 pr-6 border-t border-light-grey-03;

    &:first-child {
      @apply pl-6;
    }
  }
}

.admin-grid.compact {
  @apply text-vs;

  > thead > tr > th {
    @apply text-xs text-left py-3 pr-3 font-normal;

    &:first-child {
      @apply pl-3;
    }

    &:last-child {
      @apply pr-0;
    }
  }

  > tbody > tr > td {
    @apply text-left py-2 pr-3 border-t border-light-grey-03;

    &:first-child {
      @apply pl-3;
    }
  }
}

.admin-grid.medium {
  > thead > tr > th {
    @apply text-xs text-left py-4 pr-4 font-normal;

    &:first-child {
      @apply pl-4;
    }

    &:last-child {
      @apply pr-0;
    }
  }

  > tbody > tr > td {
    @apply text-left py-3 pr-4 border-t border-light-grey-03;

    &:first-child {
      @apply pl-4;
    }
  }
}

.pagination {
  > a,
  > span,
  > em {
    padding: 0.1rem 0.5rem;

    @apply text-center rounded bg-white mr-1 text-primary-grey-blue-02 inline-block;
  }

  > em.current {
    @apply bg-primary-grey-blue-02;
    @apply text-white;
    @apply font-bold;
    @apply not-italic;
  }
}

.training-component {
  h1 {
    @apply text-xl;
    @apply font-bold;
    @apply mt-12 mb-4;
  }

  h2 {
    @apply text-lg;
    @apply font-bold;
    @apply mt-12 mb-4;
  }

  h3 {
    @apply font-bold;
    @apply mt-12 mb-4;
  }

  p {
    @apply mb-6;
  }

  ol,
  ul {
    padding-left: 1rem;
  }

  li {
    list-style-type: disc;
    margin-bottom: 0.75rem;
  }

  table {
    border-collapse: collapse;

    @apply w-full;
    @apply mt-2;
    @apply mb-8;
  }

  table,
  th,
  td {
    @apply border-primary-grey-blue;
    @apply border;
  }

  th,
  td {
    padding: 1rem;
  }

  textarea {
    @extend .input-raw;

    display: block;
    width: 100%;
  }

  blockquote {
    @apply pl-4 border-l-5 border-primary-grey-blue leading-tight mt-2 mb-8;

    p {
      padding: 0;
      margin: 0;
    }
  }

  p > a {
    color: #0366d6;

    &:hover {
      text-decoration: underline;
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.view-container,
.result-container,
.form-container {
  @apply bg-white pt-10 pb-16;

  @extend .x-padded;
}

.input-raw {
  @apply border border-light-grey-darker rounded px-4 py-2 text-button bg-light-grey-02;
}

.input-full {
  input:not([type="checkbox"]):not([type="radio"]),
  textarea,
  select {
    @apply w-full;
  }
}

.form-inputs {
  .input {
    @apply mb-4;

    input.boolean {
      @apply mr-2;
    }

    > label {
      @apply text-sm block mb-2;
    }

    > input,
    > select,
    > textarea {
      @extend .input-raw;
    }

    > select {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .input-nomb {
    @apply mb-0;
  }

  .inline-input {
    @extend .input;

    > label {
      display: inline-block;
    }
  }

  .concise {
    @apply mb-0;
  }

  .check_boxes,
  .radio_buttons,
  .radio_boxes {
    .checkbox,
    .radiobox,
    .radio {
      @apply block text-sm;

      input[type="checkbox"],
      input[type="radio"] {
        @apply mr-2;
      }
    }
  }
}

.change-subscription-status {
  @apply font-bold;

  > option {
    background-color: white;
    color: black;
  }
}

.change-subscription-status.active {
  border-width: 0 !important;
  color: white !important;
  background-color: #00d278 !important;
}

.change-subscription-status.declined {
  border-width: 0 !important;
  color: white !important;
  background-color: #ffb90b !important;
}

.training-form {
  @apply mx-16;
}

.training-form-sections {
  list-style: none;
  counter-reset: item;
  position: relative;

  li {
    counter-increment: item;

    &::before {
      content: counter(item);

      @apply h-6 w-6 text-center rounded rounded-full bg-primary-grey-blue-02 mr-2 text-white inline-block font-bold;
    }
  }
}

.white-bg-inputs {
  input,
  textarea,
  select {
    background-color: white !important;
  }
}

.error-message {
  @apply text-red text-sm py-2;
}

.training-component-editable {
  @apply bg-light-grey-03 px-10 py-6;
}

span.error {
  color: red;

  @apply text-sm font-bold mt-1;
}

.error_notification {
  color: red;

  @apply text-sm font-bold mb-4;
}

.view-fields {
  @apply w-33rem mx-auto;

  .field {
    @apply mb-4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .label {
    @apply font-bold;
  }
}

.simple_form {
  @extend .form-inputs;

  .input:not(.date):not(.datetime) {
    > input,
    > textarea,
    > select {
      @apply w-full;
    }
  }

  max-width: 33rem;
  @apply mx-auto;
}

.form-actions {
  @apply mt-12;
}

.dropdown-menu {
  @apply py-4;

  > ul > li > a {
    @apply block py-1;
  }

  @screen md {
    @apply absolute hidden z-50 text-left py-0;

    > ul {
      @apply bg-white shadow-menu rounded mt-1;

      > li {
        > a {
          @apply w-full h-full px-4 block py-2;

          &.active,
          &:hover {
            @apply font-bold;
          }
        }
      }
    }
  }
}

.dropdown:hover .dropdown-menu {
  @screen md {
    display: block;
  }
}

.dropdown-anchor {
  @apply text-medium-grey-02;

  &:hover {
    cursor: pointer;

    @apply text-primary-grey-blue;
  }
}

.dropdown-anchor.active {
  @apply text-primary-grey-blue;

  .anchor-text {
    @apply pb-1;
    @apply border-b-4;
    @apply border-primary-yellow;
  }
}

.hero-bg {
  background-image: url("../images/home-hero-sm.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @screen lg {
    background-image: url("../images/home-hero.jpg");
  }
}

ul.top-menu {
  > li {
    @apply py-2;

    @screen md {
      @apply px-4;

      display: inline-block;
      margin-right: 1.75rem;

      @apply p-0;
    }

    > a {
      @apply font-bold text-medium-grey-02;

      &.active {
        @apply text-primary-grey-blue pb-1 border-b-4 border-primary-yellow;
      }

      &:hover {
        @apply text-primary-grey-blue;
      }
    }
  }
}

.progress-item-50 {
  @apply border-b-2 border-light-grey-darker text-light-grey-darker w-1/2 text-sm pb-2;
}

.progress-item {
  @apply border-b-2 border-light-grey-darker text-light-grey-darker w-1/3 text-sm pb-2;
}

.progress-item-active {
  @extend .progress-item;

  @apply border-primary-grey-blue text-primary-grey-blue;
}

.progress-item-active-50 {
  @extend .progress-item-50;

  @apply border-primary-grey-blue text-primary-grey-blue;
}

.tlac-cameratag-actions {
  width: 100%;
  height: 100%;
}

.training-component-container {
  @apply max-w-xl2 mx-auto px-6;

  @screen lg {
    @apply px-0;
  }
}

.training-component-container-long {
  @apply max-w-4xl mx-auto;
}

.training-component-alert {
  p {
    @apply my-0;
    @apply mx-auto;
    @apply max-w-xl2;

    padding: 0;
  }
}

.cursor-grab:hover {
  cursor: grab;
}

.dragging,
.dragging:hover {
  cursor: grabbing;
  list-style-type: none;
}

.dragging-module {
  @apply text-primary-grey-blue-02;

  border: 1px solid #ffba00 !important;
  background-color: white;
  display: table !important;
}

// cookieconsent
.cc-window {
  &.cc-floating {
    left: calc((100% - 24em) / 2) !important;
  }
}

.field-error {
  @apply my-2;
  @apply text-sm;

  color: red;
}

.rl-audio-loading {
  @apply bg-light-grey-01;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;
}

.rl-audio-error {
  @apply bg-light-grey-01;
  margin-bottom: 1rem;
  color: red;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;
}

.rl-audio {
  @apply bg-light-grey-01;

  height: 6rem;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  .rl-audio-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .rl-audio-bg {
    @apply bg-translucid;

    background-size: 100% auto;
    bottom: 0;
    height: 1rem;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .rl-audio-progress {
    background-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0));
    bottom: 0;
    height: 1rem;
    position: absolute;
    transition: width 0.5s ease;
    width: 0;
    z-index: 2;
  }

  .rl-audio-title {
    font-weight: bold;
    left: 5rem;
    position: absolute;
    text-align: left;
    top: 1rem;
    width: 90%;
  }

  .rl-audio-play,
  .rl-audio-pause {
    @apply text-red;

    cursor: pointer;
    left: 0.5rem;
    position: absolute;
    top: 0.25rem;
  }
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 0;
  background: none;
  overflow: initial;
  border-radius: 0;
  outline: none;
  padding: 0;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  @screen lg {
    width: initial;
    height: initial;
    min-width: 46rem;
    max-height: 90vh;
  }
}

.onboard {
  @apply bg-light-grey-02;
  @apply w-full;
  @apply h-full;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(57, 74, 87, 0.8);
  overflow-y: auto;
  z-index: 9999;
}

.tlac-modal-body {
  @apply bg-light-grey-02;

  min-height: 100vh;

  @screen lg {
    @apply mb-8;

    min-height: initial;
  }
}

.x-padded {
  @apply px-6;

  @screen lg {
    @apply px-0;
  }
}

.y-padded-bottom {
  @apply pb-10;

  @screen lg {
    padding-bottom: 5rem;
  }
}

.y-padded-top {
  @apply pt-10;

  @screen lg {
    padding-top: 5rem;
  }
}

.y-padded-top-min {
  @apply pt-8;
}

.y-padded {
  @apply py-10;

  @screen lg {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.y-padded-min {
  @apply py-10;
}

.practice-underline {
  position: relative;

  &::after {
    content: "Practice";
    color: transparent;
    background-image: url("../images/practice-underline-01.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    position: absolute;
    top: 0.5rem;
    left: 0;
    pointer-events: none;
  }
}

.catalog-thumb {
  width: 250px;
  height: 140px;
  object-fit: cover;

  @media (min-resolution: 192dpi) {
    width: 500px;
    height: 280px;
    object-fit: cover;
  }
}

.my-progress-thumb {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.flex-responsive {
  display: flex;
  flex-wrap: wrap;

  @screen md {
    flex-wrap: nowrap;
  }
}

.alert {
  @apply text-primary-grey-blue-02 bg-alert-info;
}

.alert.alert-danger {
  @apply text-red-alert bg-light-red-alert;
}

.module-assignments.busy {
  pointer-events: none;
  filter: blur(2px);
}

.progress-signup-cta {
  display: block;
  width: 100%;
  text-align: center;

  @screen xl {
    float: left;
    margin-left: 57rem;
    margin-top: -4rem;
    width: 8rem;
  }
}

.remote-cta {
  @apply w-3/4 text-white text-center p-4 relative;

  background-image: url("../images/remote-learning-block-01.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: auto;
  margin-right: 0;
}

.remote-cta::before {
  content: url("../images/remote-learning-star-01.svg");
  position: absolute;
  left: -4rem;
  top: 0.25rem;
}

.tour-vid-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.try-remote-cta {
  position: relative;
}

.version-3-badge {
  position: relative;
  ::after {
    background-image: url("../images/3-0-star-01.svg");
    background-size: 70px;
    content: "";
    position: absolute;
    left: -2.5rem;
    top: -1.5rem;
    height: 70px;
    width: 70px;
  }
}

.try-remote-cta::after {
  background-image: url("../images/3-0-star-01.svg");
  background-size: 45px;
  content: "";
  position: absolute;
  right: -2rem;
  top: -1rem;
  height: 45px;
  width: 45px;
}

.try-remote-body-cta {
  position: relative;
}

.try-remote-body-cta::after {
  background-image: url("../images/remote-learning-star-01.svg");
  background-size: 50px;
  content: "";
  position: absolute;
  left: -3rem;
  top: -1rem;
  height: 50px;
  width: 50px;
}

.resource-subheading {
  @apply text-xl;
  @apply font-semibold;
}

// Tooltips
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
